// @ts-nocheck
import SPINNER from '../assets/images/Spinner.gif';
import URGENTLY_LOGO_BLACK from '../assets/images/newAudit/urgently.svg';
import VOLVO_LOGO from '../assets/images/newAudit/volvo.svg';
import AMFAM_LOGO from '../assets/images/newAudit/am-fam.svg';
import BMW_LOGO from '../assets/images/newAudit/bmw.svg';
import E_FLEET_LOGO from '../assets/images/newAudit/efleets.svg';
import ELEPHANT_LOGO from '../assets/images/newAudit/elephant.svg';
import ENTERPRISE_LOGO from '../assets/images/newAudit/enterprise.svg';
import GRANGE_LOGO from '../assets/images/newAudit/grange.svg';
import LM_LOGO from '../assets/images/newAudit/liberty-mutual.svg';
import MBUSA_LOGO from '../assets/images/newAudit/mbusa.svg';
import UBER_LOGO from '../assets/images/newAudit/uber.svg';
import RANDOM_PARTNER_LOGO from '../assets/images/newAudit/random.svg';
import TIME_AVATAR from '../assets/images/newAudit/eta.svg';
import PERSON_AVATAR from '../assets/images/newAudit/person_avatar.svg';
import PICK_UP_LOGO from '../assets/images/newAudit/pick-up.svg';
import DROP_OFF_LOGO from '../assets/images/newAudit/drop-off.svg';
import PROVIDER_AVATAR from '../assets/images/newAudit/provider_avatar.svg';
import HEADSET_LOGO from '../assets/images/newAudit/headset.svg';
import CALIBER_LOGO from '../assets/images/newAudit/caliber.svg';
import JLR_LOGO from '../assets/images/newAudit/jlr.svg';

// export Images
export const SPINNER_IMG = SPINNER;
export const URGENTLY_LOGO_BLACK_IMG = URGENTLY_LOGO_BLACK;
export const VOLVO_LOGO_IMG = VOLVO_LOGO;
export const AMFAM_LOGO_IMG = AMFAM_LOGO;
export const BMW_LOGO_IMG = BMW_LOGO;
export const E_FLEET_LOGO_IMG = E_FLEET_LOGO;
export const ELEPHANT_LOGO_IMG = ELEPHANT_LOGO;
export const ENTERPRISE_LOGO_IMG = ENTERPRISE_LOGO;
export const GRANGE_LOGO_IMG = GRANGE_LOGO;
export const LM_LOGO_IMG = LM_LOGO;
export const MBUSA_LOGO_IMG = MBUSA_LOGO;
export const UBER_LOGO_IMG = UBER_LOGO;
export const RANDOM_PARTNER_LOGO_IMG = RANDOM_PARTNER_LOGO;
export const TIME_AVATAR_IMG = TIME_AVATAR;
export const PERSON_AVATAR_IMG = PERSON_AVATAR;
export const PICK_UP_LOGO_IMG = PICK_UP_LOGO;
export const DROP_OFF_LOGO_IMG = DROP_OFF_LOGO;
export const PROVIDER_AVATAR_IMG = PROVIDER_AVATAR;
export const HEADSET_LOGO_IMG = HEADSET_LOGO;
export const CALIBER_LOGO_IMG = CALIBER_LOGO;
export const JLR_LOGO_IMG = JLR_LOGO;
