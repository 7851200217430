import { CaseTypes } from '../../types/reducerTypes';
import { GET_AUDIT_EVENT_SUCCESS } from './homeActionTypes';

const caseData: CaseTypes = {
  content: [],
};

export const homeReducer = (state = caseData, action: any): object => {
  switch (action.type) {
    case GET_AUDIT_EVENT_SUCCESS:
      return {
        content: [...action.response.content],
      };
    default:
      return state;
  }
};
