import { ConfigInitialStateTypes } from '../../types/reducerTypes';
import {
  HIDE_LOADING,
  ERROR_OCCUR,
  SHOW_LOADING,
  ERROR_REMOVE,
  KEYS_SUCCESS,
} from './configActionTypes';

export const commonInitialState: ConfigInitialStateTypes = {
  isLoading: false,
  error: false,
  errorData: '',
  keys: {},
};
export const configReducer = (
  state = commonInitialState,
  action: any
): object => {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case HIDE_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case ERROR_OCCUR:
      return {
        ...state,
        error: true,
        errorData: action?.response?.error,
      };
    case ERROR_REMOVE:
      return {
        ...state,
        error: false,
        errorData: '',
      };
    case KEYS_SUCCESS:
      return {
        ...state,
        keys: action.response.data[0].config,
      };
    default:
      return state;
  }
};
