import translation from '../utils/language';
import {
  URGENTLY_LOGO_BLACK_IMG,
  VOLVO_LOGO_IMG,
  AMFAM_LOGO_IMG,
  BMW_LOGO_IMG,
  E_FLEET_LOGO_IMG,
  ELEPHANT_LOGO_IMG,
  ENTERPRISE_LOGO_IMG,
  GRANGE_LOGO_IMG,
  LM_LOGO_IMG,
  MBUSA_LOGO_IMG,
  UBER_LOGO_IMG,
  TIME_AVATAR_IMG,
  PERSON_AVATAR_IMG,
  PICK_UP_LOGO_IMG,
  DROP_OFF_LOGO_IMG,
  PROVIDER_AVATAR_IMG,
  HEADSET_LOGO_IMG,
  CALIBER_LOGO_IMG,
  JLR_LOGO_IMG,
} from '../utils/images';

export const routes = {
  AUDIT_EVENT: `/audit-event`,
};

export const titleCase = (input: string, seperator: string): string => {
  const splitStr = input.toLowerCase().split(seperator);
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

const NEW_AUDIT_EVENTS_LIST = {
  URGENTLY_TEXT_WITH_URGENTLY_LOGO: [
    'CASE_CLOSED',
    'CASE_OPENED',
    'CHANGE_STORAGE_TIME',
    'CLAIM_REQUEST',
    'CUSTOMER_NOTES',
    'CUSTOMER_VEHICLE_DETAILS_UPDATED',
    'JOB_ASSIGNED',
    'JOB_CANCELLED_BY_CUSTOMER',
    'JOB_CANCELLED_BY_CUSTOMER_CHARGEABLE',
    'JOB_CANCELLED_BY_DRIVER',
    'JOB_CANCELLED_BY_DRIVER_FOR_CUSTOMER',
    'JOB_CANCELLED_BY_PROVIDER',
    'JOB_CANCELLED_BY_PROVIDER_FOR_CUSTOMER',
    'JOB_CHANGE_CHANGE_DRIVER',
    'JOB_CANCELLED_OUT_OF_SERVICE_AREA',
    'JOB_COMPLETED_BY_PROVIDER_REQUIRES_TOW',
    'JOB_NO_PROVIDERS_FOUND',
  ],
  URGENTLY_TEXT_WITH_TIME_LOGO: [
    'JOB_CHANGE_ETA_UPDATED',
    'ONSITE_TO_DROPOFF_ESTIMATED_MINS',
    'JOB_CHANGE_TO_SCHEDULED_DROP_OFF',
    'JOB_CHANGE_TO_SCHEDULED_PICKUP',
  ],
  URGENTLY_TEXT_WITH_PERSON_LOGO: [
    'JOB_PROVIDER_ARRIVED',
    'JOB_PROVIDER_ARRIVING',
    'JOB_PROVIDER_LEFT_SITE',
  ],
  URGENTLY_TEXT_WITH_PROVIDER_ICON: [
    'JOB_CHANGE_PROVIDER_CHANGED',
    'JOB_PROVIDER_ON_THE_WAY',
  ],
  CHAT: [
    'CHAT_FROM_CSR_TO_PARTNER',
    'CHAT_FROM_PARTNER_TO_CSR',
    'CHAT_FROM_DISPATCHER_TO_CSR',
    'CHAT_FROM_CSR_TO_DISPATCHER',
  ],
  LOCATION: [
    'JOB_CHANGE_DROPOFF_LOCATION_CHANGED',
    'JOB_CHANGE_LOCATION_CHANGED',
  ],
  DRIVER_MESSAGE: [
    'DRIVER_MESSAGE',
    'CUSTOMER_MESSAGE',
    'CSR_CONTACTED_CUSTOMER',
  ],
  PUBLIC_CSR_NOTES: ['PUBLIC_CSR_NOTES'],
};

const NEW_AUDIT_PARTNER_IMAGES: { [index: string]: any } = {
  volvo: VOLVO_LOGO_IMG,
  'volvo.ewp': VOLVO_LOGO_IMG,
  'amfam.insurance': AMFAM_LOGO_IMG,
  BMW: BMW_LOGO_IMG,
  'bmw.tr': BMW_LOGO_IMG,
  'bmw.demo': BMW_LOGO_IMG,
  'bmw.bmw': BMW_LOGO_IMG,
  'bmw.mini': BMW_LOGO_IMG,
  'bmw.rollsroyce': BMW_LOGO_IMG,
  'bmw.motorrad': BMW_LOGO_IMG,
  'efleet.rm': E_FLEET_LOGO_IMG,
  'efleet.rsa': E_FLEET_LOGO_IMG,
  'elephant.insurance': ELEPHANT_LOGO_IMG,
  elephant: ELEPHANT_LOGO_IMG,
  'ride-enterprise': ENTERPRISE_LOGO_IMG,
  'enterprise.carshare': ENTERPRISE_LOGO_IMG,
  grange: GRANGE_LOGO_IMG,
  lm: LM_LOGO_IMG,
  mbusa: MBUSA_LOGO_IMG,
  MBSD: MBUSA_LOGO_IMG,
  'uber.on.demand': UBER_LOGO_IMG,
  uber: UBER_LOGO_IMG,
  'caliber.collision': CALIBER_LOGO_IMG,
  'jaguar.usa': JLR_LOGO_IMG,
  'jaguar.can': JLR_LOGO_IMG,
  'landrover.usa': JLR_LOGO_IMG,
  'landrover.can': JLR_LOGO_IMG,
};

export const newAuditEvents = (
  event: string,
  partnerCode: string,
  product: string
): { name: string; image: string } => {
  if (
    NEW_AUDIT_EVENTS_LIST.URGENTLY_TEXT_WITH_URGENTLY_LOGO.indexOf(event) > -1
  ) {
    return {
      name: translation.URGENTLY,
      image: URGENTLY_LOGO_BLACK_IMG,
    };
  } else if (
    NEW_AUDIT_EVENTS_LIST.URGENTLY_TEXT_WITH_TIME_LOGO.indexOf(event) > -1
  ) {
    return {
      name: translation.URGENTLY,
      image: TIME_AVATAR_IMG,
    };
  } else if (NEW_AUDIT_EVENTS_LIST.CHAT.indexOf(event) > -1) {
    if (event === 'CHAT_FROM_CSR_TO_PARTNER') {
      return {
        name: translation.URGENTLY_TO_PARTNER,
        image: URGENTLY_LOGO_BLACK_IMG,
      };
    } else if (event === 'CHAT_FROM_DISPATCHER_TO_CSR') {
      return {
        name: translation.PROVIDER,
        image: HEADSET_LOGO_IMG,
      };
    } else if (event === 'CHAT_FROM_CSR_TO_DISPATCHER') {
      return {
        name: translation.URGENTLY,
        image: URGENTLY_LOGO_BLACK_IMG,
      };
    } else {
      let partnerImage = NEW_AUDIT_PARTNER_IMAGES[partnerCode];
      if (partnerImage === undefined) {
        partnerImage = URGENTLY_LOGO_BLACK_IMG;
      }
      return {
        name: translation.PARTNER_TO_URGENTLY,
        image: partnerImage,
      };
    }
  } else if (
    NEW_AUDIT_EVENTS_LIST.URGENTLY_TEXT_WITH_PERSON_LOGO.indexOf(event) > -1
  ) {
    return {
      name: translation.URGENTLY,
      image: PERSON_AVATAR_IMG,
    };
  } else if (
    NEW_AUDIT_EVENTS_LIST.URGENTLY_TEXT_WITH_PROVIDER_ICON.indexOf(event) > -1
  ) {
    return {
      name: translation.URGENTLY,
      image: PROVIDER_AVATAR_IMG,
    };
  } else if (NEW_AUDIT_EVENTS_LIST.LOCATION.indexOf(event) > -1) {
    if (event === 'JOB_CHANGE_DROPOFF_LOCATION_CHANGED') {
      return {
        name: translation.URGENTLY,
        image: DROP_OFF_LOGO_IMG,
      };
    } else {
      return {
        name: translation.URGENTLY,
        image: PICK_UP_LOGO_IMG,
      };
    }
  } else if (NEW_AUDIT_EVENTS_LIST.DRIVER_MESSAGE.indexOf(event) > -1) {
    if (event === 'DRIVER_MESSAGE') {
      return {
        name: translation.DRIVER,
        image: PROVIDER_AVATAR_IMG,
      };
    } else if (event === 'CUSTOMER_MESSAGE') {
      return {
        name: translation.CUSTOMER_MESSAGE,
        image: PERSON_AVATAR_IMG,
      };
    } else {
      return {
        name: translation.URGENTLY_MESSAGE,
        image: URGENTLY_LOGO_BLACK_IMG,
      };
    }
  } else if (NEW_AUDIT_EVENTS_LIST.PUBLIC_CSR_NOTES.indexOf(event) > -1) {
    if (product && product.length > 0) {
      if (product.toLowerCase() === 'css') {
        return {
          name: translation.NOTES_FROM_CSS,
          image: URGENTLY_LOGO_BLACK_IMG,
        };
      } else if (product.toLowerCase() === 'customer_partner') {
        return {
          name: translation.NOTES_FROM_PP,
          image: URGENTLY_LOGO_BLACK_IMG,
        };
      } else if (product.toLowerCase() === 'dealer-portal') {
        return {
          name: translation.NOTES_FROM_DEALER,
          image: URGENTLY_LOGO_BLACK_IMG,
        };
      } else if (product.toLowerCase() === 'mtt') {
        return {
          name: translation.NOTES_FROM_MTT,
          image: URGENTLY_LOGO_BLACK_IMG,
        };
      } else if (product.toLowerCase() === 'case-form') {
        return {
          name: translation.NOTES_FROM_CASE_FORM,
          image: URGENTLY_LOGO_BLACK_IMG,
        };
      }
    }
    return {
      name: translation.NOTES_FROM_CSR,
      image: URGENTLY_LOGO_BLACK_IMG,
    };
  }
  return {
    name: translation.URGENTLY,
    image: URGENTLY_LOGO_BLACK_IMG,
  };
};
