import axios, { AxiosPromise } from 'axios';
import { getStore } from '../store/store';

const API = (
  methodType: any,
  endpoint: string,
  payload?: any,
  authRequire?: boolean
): AxiosPromise => {
  const headers: any = {
    'Content-Type': 'application/json',
  };
  if (authRequire) {
    const store = getStore().getState();
    headers['auth-token'] = store.authentication?.authData?.authToken;
  }
  return axios({
    method: methodType,
    url: endpoint,
    data: payload,
    headers,
  });
};

axios.interceptors.request.use((request) => {
  return request;
});
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    throw error;
  }
);
export default API;
