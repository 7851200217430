import { put, takeLatest } from 'redux-saga/effects';
import {
  HIDE_LOADING,
  SHOW_LOADING,
  ERROR_REMOVE,
} from '../config/configActionTypes';
import {
  GET_AUDIT_EVENT,
  GET_AUDIT_EVENT_SUCCESS,
  GET_JOB_AUDIT_EVENT,
} from './homeActionTypes';
import {
  HTTP_POST,
  GET_NEW_AUDIT_EVENTS,
  GET_NEW_JOB_AUDIT_EVENTS,
} from '../../constants/apiEndPoint';
import API from '../../utils/API';

import { sendMessage } from '../../utils/eventHandler';

function* getAuditData(params: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(
      HTTP_POST,
      GET_NEW_AUDIT_EVENTS(params.param),
      {},
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      yield put({ type: GET_AUDIT_EVENT_SUCCESS, response: response.data });
      yield put({ type: ERROR_REMOVE });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    sendMessage(error.response.data);
  }
}

function* getJobAuditData(params: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(
      HTTP_POST,
      GET_NEW_JOB_AUDIT_EVENTS(params.param),
      {},
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      yield put({ type: GET_AUDIT_EVENT_SUCCESS, response: response.data });
      yield put({ type: ERROR_REMOVE });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    sendMessage(error.response.data);
  }
}

export default function* homeSaga(): any {
  yield takeLatest(GET_AUDIT_EVENT, getAuditData);
  yield takeLatest(GET_JOB_AUDIT_EVENT, getJobAuditData);
}
