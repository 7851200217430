export const translations = {
  EMAIL: `Email`,
  PASSWORD: `Password`,
  LOGIN: `Log in`,
  SHOW_PASSWORD: `Show Password`,
  FORGOT: `Forgot Password?`,
  REQUEST_RESET_LINK: `Request Reset Link`,
  SOMETHING_WENT_WRONG: `Something Went wrong!`,
  WELCOME_TO_PARTNER_MANAGEMENT: `Welcome to Partner management`,
  LOGIN_SCREEN: `Login Screen`,
  LOGIN_SUBMITTED_GA_EVENT: `Login form Submitted`,
  FORGOT_PASSWORD_SCREEN: `Forgot Password Screen`,
  FORGOT_PASSWORD_SUBMITTED_GA_EVENT: `Forgot Password form Submitted`,
  FORGOT_PASSWORD_FORM_TEXT: `Enter your account email address and we’ll get you back on track!`,
  HOME_SCREEN: `Home Screen`,
  GET_CASE_LIST: `Get Cases List`,
  FILTER_CASE_LIST: `Filter Case List`,
  SEARCH_CASES: `Search Cases`,
  ALL: `All Cases`,
  OPEN: `Open`,
  CLOSED: `Closed`,
  NO_CASES: `You have no outstanding service requests!`,
  THIRD_PARTY_NETWORK: `3rd Party Network`,
  AUDIT_EVENT_SCREEN: `Audit event Screen`,
  REACT_INIT_AUDIT_EVENT: `REACT_INIT_AUDIT_EVENT`,
  PROVIDER: `Provider`,
  DRIVER: `Driver`,
  DEFAULT_DRIVER_MESSAGE: `Driver is chatting with Urgently`,
  PROVIDER_TO_URGENTLY_CHAT: `Provider is chatting with Urgently`,
  URGENTLY_TO_PROVIDER_CHAT: `Urgently is chatting with Provider`,
  CUSTOMER_MESSAGE: `Customer is chatting with Urgently`,
  URGENTLY_MESSAGE: `Urgently is chatting with Customer`,
  JOB_STATUS: {
    NEW: 'New',
    URGENTLY_DISPATCH: `Urgent.ly Dispatch`,
    ASSIGNED: `Assigned`,
    ON_THE_WAY: `On The Way`,
    ON_SITE: `On Site`,
    TOW_UNDERWAY: `Tow Underway`,
    PROVIDER_ARRIVED: `Provider Arrived`,
    CREDIT_CARD_AUTHORIZED: `Credit Card Authorized`,
    CREDIT_CARD_AUTHORIZATION_VOIDED: `Credit Card Authorization Voided`,
    COMPLETED_BY_PROVIDER: `Completed by Provider`,
    COMPLETED: `Completed`,
    CANCELLED_BY_PROVIDER: `Cancelled by Provider`,
    JOB_COMPLETE_REQUIRE_TOW: `Job Complete require TOW`,
    PROVIDER_HAS_LEFT_SITE: `Provider has left Site`,
    NO_PROVIDER_FOUND: `No Provider Found`,
    CANCELLED_BY_CUSTOMER: `Cancelled by customer`,
    CANCELLED_BY_DRIVER_FOR_CUSTOMER: `Cancelled by Driver for Customer`,
    EXPIRED: `Expired`,
    COMPLETED_BY_PROVIDER_GOA: `Completed by Provider GOA`,
    GOA: `GOA`,
    SCHEDULED: `Scheduled`,
    DEMO: `Demo`,
    CANCEL_WITH_CHARGE: `Cancel with charge`,
    CLOSED_CANCELLED: `Closed Cancelled`,
    NO_CREDIT_CARD_ON_FILE: `No Credit Card on file`,
    PAYMENT_FAILED: `Payment Failed`,
    ALREADY_COMPLETE: `Already Complete`,
    NO_PROVIDERS_AVAILABLE: `No Providers Available`,
    CLOSED_PAID_BY_CASH: `Closed Paid by Cash`,
    CLOSED_GOA_NO_PAYMENT: `Closed GOA No Payment`,
    CLOSED_PAYMENT_FAILED: `Closed Payment Failed`,
    CLOSED_GOA_PAYMENT_FAILED: `Closed GOA Payment Failed`,
    CLOSED_ALL_PROVIDERS_REJECTED: `Closed All Providers Rejected`,
    CLOSED_NO_PAYMENT_NEEDED: `Closed No Payment Needed`,
    CLOSED_GOA: `Closed GOA`,
    CLOSED: `Closed`,
    SYSTEM_ENTERED: `System Entered`,
    CLOSED_CANCEL_WITH_CHARGE: `Closed - Cancel with charge`,
    POTENTIAL_ONLY: `Potential Only`,
    CLOSED_TEST: `CLOSED - TEST`,
    NO_STATUS: `No status`,
    CONTACTING_PROVIDERS: `Contacting Providers...`,
    PENDING: `Pending`,
  },
  CASE_STATUS: {
    COMPLETE: 'complete',
    LIVE: 'live',
  },
  ENTITY: {
    FACILITY: 'facility',
    PARTNER: 'partner',
  },
  SERVICES: {
    JUMP_START: `Jump Start`,
    KEY_REPLACEMENT: `Key Replacement`,
    FLAT_TIRE: `Flat Tire`,
    FUEL: `Fuel`,
    LOCKOUT: `Lockout`,
    TOWING: `Towing`,
    TOW: `TOW`,
    TIRE_INSTALL: `Tire Install`,
    MOTORCYCLE_TOW: `Motorcycle Tow`,
  },
  BUILD: `Build`,
  API_BUILD: `API Build`,
  LOGOUT: `Log Out`,
  DAMAGE_DETAILS: `Damage Details`,
  POC: `Case Point Of Contact`,
  TODAY: `Today`,
  CASE_CREATED_ON: `Case created on`,
  URGENTLY: `Urgently`,
  PARTNER_TO_URGENTLY: `Partner is chatting with urgently`,
  URGENTLY_TO_PARTNER: `Urgently is chatting with partner`,
  NOTES_FROM_CSR: `Note from Customer Service Representative`,
  NOTES_FROM_CSS: `Note from Urgently`,
  NOTES_FROM_PP: `Note from Partner Portal`,
  NOTES_FROM_DEALER: `Note from Dealer Portal`,
  NOTES_FROM_MTT: `Note from MTT Portal`,
  NOTES_FROM_CASE_FORM: `Note from Case Form`,
  PARTNER_ADD_NOTE: `Partner Added A Note To The Job`,
};
