import React from 'react';
import { connect } from 'react-redux';
import {
  ConfigInitialStateTypes,
  AuditData,
  CaseDataAudit,
  AuditList,
} from '../types/reducerTypes';
// @ts-ignore
import { withRouter } from 'react-router';
import translation from '../utils/language';
import moment from 'moment';
import AuditEventScreen from '../components/auditEvent/auditEvent';
import { printLog } from '../utils/logging';
import { ANGULAR_AUTH_SUCCESS } from '../saga/login/loginActionTypes';
import { Dispatch } from 'redux';
import {
  GET_AUDIT_EVENT,
  GET_JOB_AUDIT_EVENT,
} from '../saga/home/homeActionTypes';
import { titleCase, newAuditEvents } from '../constants/utilConstants';
import {
  EventName,
  Events,
  sendMessage,
  postMessage,
} from '../utils/eventHandler';

interface State {
  showSidebar: boolean;
  caseDetail: CaseDataAudit;
  auditDataList: AuditList[];
  partnerCode: string;
}
interface Props {
  config: ConfigInitialStateTypes;
  auditData: AuditData;
  getNewAudit: (params: any) => void;
  getNewJobAudit: (params: any) => void;
  setAuthToken: (Object: any) => void;
}
interface Payload {
  caseId: number;
}
class AuditEventContainer extends React.Component<Props, State> {
  screenName: string;
  constructor(props: Props) {
    super(props);
    this.screenName = translation.AUDIT_EVENT_SCREEN;
    this.state = {
      showSidebar: false,
      partnerCode: '',
      caseDetail: {
        caseJobNumber: 0,
        createdAt: 0,
        name: '',
        serviceId: 0,
        serviceType: '',
      },
      auditDataList: [
        {
          displayTime: '',
          displayDate: '',
          diffHrs: '',
          diffMins: '',
          diffSecs: '',
          name: '',
          newAuditName: '',
          image: '',
          text: '',
          createdAt: 0,
          nameInitial: '',
          eventUsers: '',
          eventLocations: '',
          id: 0,
          displayDay: '',
          isExpanded: false,
          eventTitle: '',
          email: '',
          event: '',
        },
      ],
    };
    this.receiveMessage = this.receiveMessage.bind(this);
    this.receiveJobMessage = this.receiveJobMessage.bind(this);
  }

  componentDidMount(): void {
    /* TODO: Receiving blank event on did mount that need to check */
    Events.on(EventName.ANGULAR_INIT_DATA, this.receiveMessage);
    Events.on(EventName.ANGULAR_GET_JOB_DATA, this.receiveJobMessage);
    sendMessage(postMessage);
  }

  componentDidUpdate(prevProps: any): void {
    if (prevProps.auditData?.content === this.props.auditData?.content) {
      return;
    }
    let deepCopyObj;
    const messageList = [];
    if (this.props.auditData.content.length > 0) {
      deepCopyObj = JSON.parse(JSON.stringify(this.props.auditData.content));
      for (let i = 0; i < deepCopyObj.length; i++) {
        if (deepCopyObj[i]) {
          deepCopyObj[i].displayTime = moment(deepCopyObj[i].timestamp).format(
            'hh:mm A'
          );
          deepCopyObj[i].displayDate = moment(deepCopyObj[i].timestamp).format(
            'MMMM D'
          );
          deepCopyObj[i].displayDay = moment(deepCopyObj[i].timestamp).format(
            'dddd'
          );
          const diffMs = Math.abs(
            deepCopyObj[i].timestamp - this.state.caseDetail.createdAt
          );
          deepCopyObj[i].diffHrs = Math.floor((diffMs % 86400000) / 3600000);
          deepCopyObj[i].diffMins = Math.floor(
            ((diffMs % 86400000) % 3600000) / 60000
          );
          deepCopyObj[i].diffSecs = Math.floor(
            (((diffMs % 86400000) % 3600000) % 60000) / 1000
          );
          /*if (deepCopyObj[i].diffHrs < 10) {
              deepCopyObj[i].diffHrs = '0' + deepCopyObj[i].diffHrs;
            }
            if (deepCopyObj[i].diffMins < 10) {
              deepCopyObj[i].diffMins = '0' + deepCopyObj[i].diffMins;
            }*/
          if (deepCopyObj[i].diffSecs < 10) {
            deepCopyObj[i].diffSecs = '0' + deepCopyObj[i].diffSecs;
          }
          if (deepCopyObj[i].createdBy) {
            deepCopyObj[i].createdBy = titleCase(deepCopyObj[i].createdBy, '_');
          }
          const auditData = newAuditEvents(
            deepCopyObj[i].event,
            this.state.partnerCode,
            deepCopyObj[i].product
          );
          const {
            displayTime,
            displayDate,
            diffHrs,
            diffMins,
            diffSecs,
            createdBy,
            product,
            description: text,
            timestamp: createdAt,
            eventUsers,
            eventLocations,
            displayDay,
            eventTitle,
            createdByEmail: email,
            event,
          } = deepCopyObj[i];
          let name, nameInitial;
          if (createdBy === 'Access Platform Api' && product !== undefined) {
            name = product;
          } else {
            name = createdBy;
          }
          if (createdBy) {
            const [subStr1, subStr2 = ''] = createdBy.split(' ');
            nameInitial = subStr1[0] + (subStr2[0] ?? '');
          } else {
            nameInitial = 'A';
          }
          messageList.push({
            displayTime,
            displayDate,
            diffHrs,
            diffMins,
            diffSecs,
            name,
            newAuditName: auditData.name,
            image: auditData.image,
            text,
            createdAt,
            nameInitial,
            eventUsers: eventUsers ? eventUsers : undefined,
            eventLocations: eventLocations ? eventLocations : undefined,
            id: i,
            displayDay,
            isExpanded: false,
            eventTitle,
            email,
            event,
          });
        }
      }
      messageList.sort(function (a, b) {
        return b.createdAt - a.createdAt;
      });
      this.setState({
        auditDataList: messageList,
      });
    }
  }

  receiveJobMessage = function (this: AuditEventContainer, event: any): void {
    try {
      if (event) {
        this.setState({
          caseDetail: event.caseDetail,
          partnerCode: event.partnerCode,
        });
        this.props.setAuthToken(event.authToken);
        this.props.getNewJobAudit(event.caseId);
      }
    } catch (error) {
      printLog(error);
    }
  };

  receiveMessage = function (this: AuditEventContainer, event: any): void {
    try {
      if (event) {
        this.setState({
          caseDetail: event.caseDetail,
          partnerCode: event.partnerCode,
        });
        this.props.setAuthToken(event.authToken);
        this.props.getNewAudit(event.caseId);
      }
    } catch (error) {
      printLog(error);
    }
  };

  render(): React.ReactNode {
    return (
      <>
        <AuditEventScreen
          auditData={this.state.auditDataList}
          caseCreatedAt={this.state.caseDetail.createdAt}
        />
      </>
    );
  }
}
const mapStateToProps = (props: Props): object => {
  return {
    config: props.config,
    auditData: props.auditData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
  return {
    getNewAudit: (param: number) => {
      dispatch({ type: GET_AUDIT_EVENT, param });
    },
    getNewJobAudit: (param: number) => {
      dispatch({ type: GET_JOB_AUDIT_EVENT, param });
    },
    setAuthToken: (params: string) => {
      dispatch({ type: ANGULAR_AUTH_SUCCESS, params });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuditEventContainer) as any
);
