import React from 'react';
import './auditEvent.scss';
import { AuditList } from '../../types/reducerTypes';
import moment from 'moment';
import translation from '../../utils/language';
import { Row, Col } from 'react-bootstrap';

interface Props {
  auditData: AuditList[];
  caseCreatedAt: number;
}

export const AuditEvent: React.FC<Props> = (props: Props) => {
  const date = moment(new Date()).format('MMMM D');
  const caseCreatedTime = moment(props.caseCreatedAt).format('hh:mm A');
  const caseCreatedDate = moment(props.caseCreatedAt).format('MM/DD/YYYY');
  const defaultMessage = [
    'DRIVER_MESSAGE',
    'CHAT_FROM_DISPATCHER_TO_CSR',
    'CHAT_FROM_CSR_TO_DISPATCHER',
  ];
  return (
    <div>
      {/* ----------------------- Chat History ----------------------- */}

      <Row>
        <Col sm={12}>
          <div className="chat_history_box">
            {props &&
              props.auditData &&
              props.auditData.length > 0 &&
              props.auditData[0].createdAt === 0 && (
                <div className="message">
                  <div className="no_audit_found">
                    {translation.CASE_CREATED_ON} {caseCreatedDate}{' '}
                    {caseCreatedTime}
                  </div>
                </div>
              )}
            {props?.auditData[0]?.createdAt !== 0 &&
              props.auditData.map((data: any, index: any) => {
                return (
                  <div className="message" key={index}>
                    {index === 0 &&
                      (date === data.displayDate ? (
                        <>
                          <div className="message_dt">{translation.TODAY}</div>
                          <div className="divider" />
                        </>
                      ) : (
                        <>
                          <div className="message_dt">
                            {data.displayDay},
                            <span className="time">{data.displayDate}</span>
                          </div>
                          <div className="divider" />
                        </>
                      ))}
                    {index > 0 &&
                      data.displayDate !==
                        props.auditData[index - 1].displayDate && (
                        <>
                          <div className="message_dt mt20">
                            {data.displayDay},
                            <span className="time">{data.displayDate}</span>
                          </div>
                          <div className="divider" />
                        </>
                      )}
                    <div className="chat_box">
                      <div className="thumb_initial">
                        <img src={data.image} title="" alt="" />
                      </div>
                      <div className="chat">
                        <div className="name sm_name">
                          <span className="mr5 displayName">
                            {data.newAuditName}
                          </span>
                          <span className="time">{data.displayTime}</span>
                          <span className="time_left sm_time_left">
                            <span>
                              {data.diffHrs > 0 ? `(${data.diffHrs} hrs ` : `(`}
                            </span>
                            <span>{`${data.diffMins} min)`}</span>
                          </span>
                        </div>
                        <div className="message_txt">
                          {defaultMessage.indexOf(data.event) === -1 &&
                            data.text}
                          {data.event === 'CHAT_FROM_DISPATCHER_TO_CSR' &&
                            translation.PROVIDER_TO_URGENTLY_CHAT}
                          {data.event === 'CHAT_FROM_CSR_TO_DISPATCHER' &&
                            translation.URGENTLY_TO_PROVIDER_CHAT}
                          {data.event === 'DRIVER_MESSAGE' &&
                            translation.DEFAULT_DRIVER_MESSAGE}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AuditEvent;
