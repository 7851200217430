const translations = {
  translation: (function () {
    if (process.env.REACT_APP_LANGUAGE === 'EN') {
      return require('../constants/languageEn').translations;
    } else {
      return require('../constants/languageFr').translations;
    }
  })(),
};

export default translations.translation;
